<template>
  <div class="accordionItem">
    <div class="accordionHeader" @click="toggleExpanded">
      <div class="innerWrapper">
        <span class="iconArrow"></span>
        <h3>{{ title }}</h3>
      </div>
      <span class="toggleIcon" :class="{ 'is-expanded': isExpanded }"></span>
    </div>
    <transition name="accordion">
      <div v-if="isExpanded" class="accordionContent">
        <div class="imageGrid">
          <CloudinaryImage v-for="image in images" :key="image" :public-id="image"
               :alt="`Image option`"/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions'
import { mapState } from 'vuex'
import CloudinaryImage from '@/components/CloudinaryImage.vue'

export default {
  name: 'AccordionItem',
  components: { CloudinaryImage },
  props: {
    title: String,
    expanded: Boolean,
    baseUrl: String
  },
  data () {
    return {
      isExpanded: this.expanded,
      images: []
    }
  },
  methods: {
    toggleExpanded () {
      this.isExpanded = !this.isExpanded
    }
  },
  computed: {
    ...mapState(['fbFunctions'])
  },
  async created () {
    const res = await httpsCallable(this.fbFunctions, 'listAllAssetsFromFolderFromCloudinary')({ path: this.baseUrl })
    this.images = res.data?.assets ?? []
  },
  watch: {
    expanded (newValue) {
      this.isExpanded = newValue
    }
  }
}
</script>

<style scoped>
.accordionItem {
  border: 1px solid #ddd;
  border-radius: 12px;
  margin-bottom: 12px;
  padding: 10px;
  width: 100%;
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.innerWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.accordionHeader h3 {
  margin: 0;
  color: #004021;
}

.toggleIcon {
  width: 24px;
  height: 24px;
  position: relative;
  transition: transform 0.3s ease;
}

.toggleIcon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-right: 2px solid #004021;
  border-bottom: 2px solid #004021;
  transform: translate(-75%, -50%) rotate(-45deg);
  transition: transform 0.3s ease;
}

.toggleIcon.is-expanded::before {
  transform: translate(-75%, -25%) rotate(45deg);
}

.accordionContent {
  padding-top: 20px;
  overflow: hidden;
}

.accordion-enter-active,
.accordion-leave-active {
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 1000px;
  opacity: 1;
}

.accordion-enter,
.accordion-leave-to {
  max-height: 0;
  opacity: 0;
}

.iconArrow {
  content: "\e900";
  color: #004021;
  font-size: 8px;
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.iconArrow:before {
  content: "\e900";
  color: #004021;
}

.imageGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}

.imageGrid > * {
  width: calc(33.33% - 5.33px);
  height: 100%;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .imageGrid > * {
    width: 100%;
  }
}
</style>
