<template>
  <div class="inspirationHeader">
  <h1>Welcome to our Explore section!</h1>
  <p class="inspirationDescription">
    We offer three popular styles of furniture: Mid-Century Modern (Draper), California Coastal (Moody) and Industrial (Roy). In order to provide a sense of each style, you'll find:
    <ul>
      <li>Examples of <span class="emphasizedText">actual furniture options</span> that we offer</li>
      <li>Rooms to showcase the variety of each style</li>
    </ul>
    While direct purchase isn't available (yet!), have fun checking out examples of how your space will be transformed
  </p>
  </div>
  <div class="inspirationGallery">
    <div class="roomSelector">
      <router-link to="/inspiration-gallery?room=Living Room" class="inspirationButton" :class="room === 'Living Room' ? 'active' : ''">
        Living Room
      </router-link>
      <router-link to="/inspiration-gallery?room=Bedroom" class="inspirationButton" :class="room === 'Bedroom' ? 'active' : ''">
        Bedroom
      </router-link>
      <router-link to="/inspiration-gallery?room=Dining Room" class="inspirationButton" :class="room === 'Dining Room' ? 'active' : ''">
        Dining Room
      </router-link>

    </div>
    <div class="styleSectionsWrapper">
      <StyleSection :key="room" title="Draper" :room="room" description="Clean lines, sleek edges, natural woods, and dark fabrics. Functionality over form.
Timeless minimalism. Inspired by the staff at Sterling Cooper." subtitle="Classic Cool"/>
      <StyleSection :key="room" title="Moody" :room="room" description="Softer lines, woven materials &whitewashed woods including oak, teak and ash. Comfort meets style.
Warm & welcoming. Furniture you might find on Californiacation or Big Little Lies." subtitle="Casual elegance"/>
      <StyleSection :key="room" title="Roy" :room="room" description="Stark, rough, unfinished forms, dark leather, metal & glass, monochrome color scheme.
Mainstream masculinity, a style suited for Succession’s Roman or Kendall." subtitle="Edgy yet grounded"/>
    </div>
  </div>
</template>

<script>
import StyleSection from '@/components/InspirationGallery/StyleSection.vue'

export default {
  name: 'InspirationGallery',
  components: {
    StyleSection
  },
  computed: {
    room () {
      return this.$route.query.room
    }
  }
}
</script>

<style scoped lang="scss">
.inspirationGallery {
  @media (min-width: 1024px) {
    max-width: 1200px;
  }
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  color: #FFFFFF;
  font-family: League Spartan, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 44.16px;
  text-align: center;
}

.inspirationDescription {
  color: #FFFFFF;
  font-family: Sohne, serif;
  font-size: 16px;
  font-weight: 50;
  line-height: 22px;
  text-align: left;
  max-width: 950px;
  margin-top: 16px;
  padding: 10px;
}

.inspirationDescription ul {
  margin-left: 20px;
  margin-bottom: 10px;
}

.inspirationDescription li {
  list-style-type: disc !important;
}

.emphasizedText {
  color: #F6FF93;
}

.roomSelector {
  display: flex;
  gap: 12px !important;
  margin-bottom: 20px;
  overflow-x: auto;
  max-width: 100%;
}

.roomSelector button {
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 20px;
}

.roomSelector button.active {
  background-color: #333;
  color: white;
}

.styleSection h2 {
  font-size: 2em;
  margin-bottom: 5px;
}

.styleDescription {
  font-style: italic;
  color: #666;
  margin-bottom: 10px;
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.imageGrid img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.accordionSection {
  margin-top: 40px;
}

.inspirationHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $tt-brand-green;
  padding-top: 40px;
  padding-bottom: 40px;
  letter-spacing: 0.06em;
}

.inspirationHeader h1 {
  color: #FFFFFF;
  font-family: League Spartan, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 44.16px;
  text-align: center;
}

.bold {
  font-weight: 700;
}

.inspirationButton {
  padding: 16px 20px 16px 20px !important;
  gap: 8px;
  border-radius: 40px !important;
  border: 1px solid #00000026 !important;
  background: transparent !important;
  text-transform: none;
  font-family: Sohne, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.38px;
  text-align: left;
  margin: 0 !important;
  text-decoration: none !important;
  white-space: nowrap;

  &:hover {
    background-color: #F6FF93 !important;
  }
  &.active {
    background-color: #F6FF93 !important;
  }
}

.styleSectionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
